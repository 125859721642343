import Swiper, { Lazy, Autoplay } from "swiper";

(function () {
    const widgets = document.querySelectorAll(".js-product-promo");

    widgets.forEach(function (widget) {
        const carousel = widget.querySelector(".js-image-slider");

        if (carousel.querySelectorAll(".swiper-slide").length > 1) {
            new Swiper(carousel, {
                modules: [Lazy, Autoplay],
                preloadImages: false,
                lazy: {
                    enabled: true,
                    loadPrevNext: true,
                },
                speed: 500,
                slidesPerView: 1,
                centeredSlides: true,
                autoHeight: false,
                spaceBetween: 16,
                autoplay: {
                    delay: 5000,
                },
                loop: true,
                breakpoints: {
                    1024: {
                        speed: 750,
                    },
                },
            });
        }
    });
})();
